import React from 'react';
import { styled } from '@superset-ui/core';
import { Spin } from 'antd';
import Indicator from './Indicator';

const LoaderDiv = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  margin: ${({ size }: any) =>
    size === 'large' ? -24 : size === 'small' ? -7 : -15}px;
`;

const CustomLoading = (props: any) => (
  <LoaderDiv {...props}>
    <Spin indicator={<Indicator size={props.size} />} {...props} />
  </LoaderDiv>
);

export default CustomLoading;
