import React from 'react';
import classNames from 'classnames';
// import { ReactComponent as Indicator } from './loading.svg';
import { StyledIcon } from 'src/components/Icons/Icon';
import IndicatorIcon from './loading.svg';

export interface Props {
  size?: string;
  className?: string;
}

export default (props: Props) => {
  const { size: initialSize = 'default', className, ...rest } = props;
  const size =
    initialSize === 'large' ? 48 : initialSize === 'default' ? 30 : 14;
  return (
    <span className={classNames('ant-spin-dot', className)}>
      <StyledIcon
        component={IndicatorIcon}
        width={size}
        height={size}
        {...rest}
      />
    </span>
  );
};
